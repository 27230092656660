.hero-img {
  @media only screen and (min-width: 35em) {
    position: relative;
    top: 10%;
    left: -8%;
  }
  @media screen and (max-width: 90em) and (min-width: 30em) {
    position: initial;
  }
  @media screen and (max-width: 1910px) and (min-width: 1366px) {
    left: -2%;
  }
}

.hero-button {
  height: 36px;
}

.hero-title {
  @media only screen and (min-width: 35em) {
    position: relative;
    top: -5%;
  }
  @media only screen and (min-width: 1824px) {
    position: relative !important;
    left: -5%;
  }
  @media screen and (min-width: 90em) {
    align-items: flex-end;
    position: initial;
  }
  @media screen and (max-width: 89em) and (min-width: 60em) {
    align-items: center !important;
    position: initial;
  }
  @media screen and (max-width: 60em) and (min-width: 30em) {
    position: initial;
  }
}

#hero > div {
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
  }
}

.section-title {
  @media only screen and (min-width: 35em) {
    font-size: 4rem;
  }

  @media screen and (max-width: 60em) and (min-width: 30em) {
    font-size: 3rem;
  }

  @media screen and (max-width: 30em) and (min-width: 1200px) {
    font-size: 2.5rem;
  }

  font-size: 2rem;
}

.section-text {
  width: 65%;
  margin-top: 0;
}

section {
  min-height: calc(100vh - 5rem);
  position: relative;
  top: 4rem;
}
