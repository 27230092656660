.tns-nav > button {
  border-radius: 100%;
  border: none;
  margin: 0 5px;
  background-color: $primary;
  height: 10px;
}

.slidernav > div {
  height: 5px;
  width: 7px;
  border-radius: 100%;
  border: none;
  margin: 0 7px;
  background-color: $light-gray;
  height: 7px;
}

.slidernav > div.tns-nav-active {
  background-color: $primary;
}

.left {
  top: 65%;
  left: 10px;
  border-radius: 50%;
  @include mdc-button-icon-color(gray);
  @include mdc-button-ink-color(gray);
}

.right {
  top: 65%;
  right: 10px;
  height: 20px;
  border-radius: 50%;
  @include mdc-button-icon-color(gray);
  @include mdc-button-ink-color(gray);

  & i {
    position: relative;
    left: 5%;
  }
}

.link.active {
  color: $accent;
}
