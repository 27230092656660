#contact {
  position: fixed;
  top: 0;
  bottom: 0;
  transition: transform 0.5s ease-in-out;
  @media only screen and (min-width: 35em) {
    width: 400px;
    right: -400px;
  }
  width: 100%;
  right: -100%;
}

#contact.open {
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease-in-out;
  transform: translateX(-100%);
  overflow-y: scroll;
}

.close-btn {
  @include mdc-button-icon-color(gray);
  @include mdc-button-ink-color(gray);
  position: relative;
  top: -5%;
  right: -5%;
}
