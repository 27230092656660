@import "@material/textfield/mdc-text-field";
@import "@material/form-field/mdc-form-field";
@import "@material/button/mdc-button";
@import "@material/snackbar/mdc-snackbar";
@import "@material/radio/mdc-radio";

.btn-surface {
  @include mdc-ripple-surface;
  @include mdc-ripple-radius-bounded;
  @include mdc-states-base-color(#f3f3f3);
  @include mdc-states-hover-opacity(0.1);
  @include mdc-states-focus-opacity(0.3);
  @include mdc-states-press-opacity(0.4);
}

.app-btn {
  @include mdc-button-filled-accessible($primary);
}

.form-btn {
  border-radius: 2px;
  @include mdc-button-filled-accessible($accent);
}

.mdc-text-field.mdc-text-field--focused {
  @include mdc-text-field-label-color($primary);
  @include mdc-text-field-line-ripple-color($primary);
  @include mdc-text-field-bottom-line-color($primary);
  @include mdc-text-field-focused-outline-color($primary)
}


.m-area {
  @include mdc-text-field-line-ripple-color($primary);
}
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) {
  border-color: $primary;
}

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled)
  .mdc-text-field__input:focus {
  border-color: $primary;
}

