@import 'base';
@import 'colors';
@import 'vendor';

@import 'header';
@import 'hero';
@import 'slider';
@import 'contact';

$primary: #009691;
$accent: #ec407a;
$gray: #4a4a4a;
$light-gray: #cbcbcb;
$app-bg-color: #f5fafa;

#root {
  display: none;
  flex-direction: column;
}

#app {
  display: flex;
  margin: 0;
  min-height: 100vh;
  background-color: $app-bg-color;
  flex-direction: column;
}

.disabled {
  pointer-events: none;
}

header {
  position: relative;
}

.navbar {
  @media (max-width: 768px) {
    width: 30% !important;
  }
}
.no-js .lang {
  display: none;
}

.w0 {
  width: 0px;
}

.hi {
  @media screen and (max-width: 1910px) and (min-width: 1366px) {
    height: 650px;
  }
  @media (max-width: 1080px) {
    height: 500px;
  }
  @media (max-width: 30em) {
    height: initial;
  }
  object-fit: contain;
  height: 800px;
}

.roboto {
  font-family: 'Roboto Regular', -apple-system, BlinkMacSystemFont, 'avenir next',
    avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial,
    sans-serif;
}

.scrollb {
  overflow: hidden;
}

.ns {
  scroll-behavior: smooth;
}

.separator {
  border-top: 1px solid #f3f3f3;
}

.icon {
  height: 24px;
  width: 24px;
}

.footer-section {
  color: rgba(255, 255, 255, 0.5);
}

.h-h {
  height: 5rem;
}

.h-90 {
  height: 90%;
}

.h-toggle {
  position: relative;
  top: 0.5rem;
}

.f-base {
  font-size: 14px;
}

.am {
  @media screen and (min-width: 60em) {
    margin: 2rem;
  }
  border-radius: 3px;
}

textarea[name='message'] {
  overflow: hidden;
  resize: none;
}

.snack {
  transition: opacity 0.5s ease-in;
  opacity: 0;
}

.snack.on {
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

:lang(nl) .t-fix {
  position: relative;
  top: -10px;
}

:lang(nl) .s-l-fix {
  overflow: visible;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.flex {
  display: flex;
}

.flex-direction--column {
  flex-direction: column;
}

.justify {
  &--center {
    justify-content: center;
  }
  &--space-between {
    justify-content: space-between;
  }
}

.align--center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.wider {
  max-width: 800px;
}